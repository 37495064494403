import { defineStore } from 'pinia';
import type { OrderConfiguration } from '~/store/types';

export const useOrderStore = defineStore({
  id: 'order',
  state: () => ({
    orderConfiguration: undefined as OrderConfiguration | undefined,
  }),
  getters: {
    selectedOrderConfiguration: (state) => state.orderConfiguration,
  },
  actions: {
    setSelectedOrderConfiguration(c?: OrderConfiguration) {
      this.orderConfiguration = c;
    },
  },
});
