<template>
  <i :class="`flag-icon flag-icon-${isoCode.toLowerCase()}`"></i>
</template>

<script lang="ts">
export default defineComponent({
  name: 'FlagIcon',
  props: {
    isoCode: {
      type: String,
      required: true,
    },
  },
});
</script>
