<template>
  <div id="landing-page-order-configurations" class="container">
    <h2>{{ component.title }}</h2>

    <div class="row">
      <div
        v-for="config in component.orderConfigurations.slice(-3)"
        :key="config.id"
        class="col-lg-3 col-sm-6 col-12 py-3 p-sm-2"
        @click.exact="selectConfiguration(config)"
      >
        <landing-page-order-configuration
          :order-configuration="config"
          :product-key="productKey"
          :product-type="productType"
          :pre-order="preOrder"
          :selected="
            selectedConfiguration && selectedConfiguration.id === config.id
          "
        />
      </div>
      <div
        class="col-lg-3 col-sm-6 col-12 py-3 p-sm-2"
        @click.exact="selectConfiguration(undefined)"
      >
        <landing-page-custom-order-configuration
          :order-url="orderUrl"
          :pre-order="preOrder"
          :background-image="component.backgroundImage"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type {
  StrapiOrderConfigurationContent,
  StrapiOrderConfigurations,
} from '~/apollo/types/types';
import type {
  OrderConfiguration,
  OrderConfigurationLocationPrice,
} from '~/store/types';

export default defineComponent({
  name: 'StrapiProductOrderConfigurations',
  props: {
    component: {
      type: Object as PropType<StrapiOrderConfigurations>,
      required: true,
    },
    productKey: {
      type: String,
      required: true,
    },
    productType: {
      type: String,
      required: true,
    },
    orderUrl: {
      type: String,
      required: true,
    },
    preOrder: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { format } = useNumberMoneyFormatting();
    const orderStore = useOrderStore();

    const selectConfiguration = (sc?: StrapiOrderConfigurationContent) => {
      if (sc == null) {
        orderStore.setSelectedOrderConfiguration();
      } else {
        const prices = new Array<OrderConfigurationLocationPrice>();
        sc.locations.forEach((l) => {
          if (prices[0] == null) return;

          prices.push({
            slug: l.slug,
            rawPrice: {
              amount: l.prices[0].value,
              currencyCode: l.prices[0].currency,
              fraction: l.prices[0].currency === 'JPY' ? 0 : 2,
            },
            price: format({
              amount: l.prices[0].value,
              currencyCode: l.prices[0].currency,
              fraction: l.prices[0].currency === 'JPY' ? 0 : 2,
            }),
          });
        });

        orderStore.setSelectedOrderConfiguration({
          prices,
          id: sc.id,
          duration: sc.duration,
          units: sc.units,
          unitType: sc.unitType,
        });
      }
    };

    const selectedConfiguration = computed<OrderConfiguration | undefined>(
      () => orderStore.selectedOrderConfiguration,
    );

    onUnmounted(() => {
      selectConfiguration();
    });

    return {
      selectedConfiguration,
      selectConfiguration,
    };
  },
});
</script>
