<template>
  <button
    type="button"
    class="btn-scroll-top p-1 d-block mx-auto"
    data-cy="totop"
    @click="performScrollToTop"
  >
    <fa-icon :icon="faAngleUp" />
    <br />
    {{ $t('landingpage/scroll_to_top') }}
  </button>
</template>

<script setup lang="ts">
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';

const { scrollToTop } = useWindowScroll();

const performScrollToTop = (): void => scrollToTop();
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors.scss';

.btn-scroll-top {
  border: 0;
  color: $gp-gportal-green;
  border-bottom: 1px $gp-gportal-green solid;
  border-radius: 0;
  font-weight: bold;
  background-color: unset;
}
</style>
