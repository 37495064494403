<template>
  <div
    class="landing-page__top-benefits"
    :class="{ 'landing-page__top-benefits--expanded': !md && isExpanded }"
  >
    <div
      data-cy="benefits-top-bar-wrapper"
      class="container d-block d-md-flex mx-auto"
      @click="toggleExpand"
    >
      <div
        v-for="(benefit, index) in benefits"
        :key="benefit.id"
        data-cy="benefit-title"
        class="col-12 col-md-4 py-2 text-center"
        :class="{
          'text-md-start position-relative': index === 0,
          'text-md-end': index === benefits.length - 1,
        }"
      >
        <strapi-image
          fluid
          class="me-2"
          :image="benefit.icon"
          :force-width="18"
          :force-height="18"
          :alt="benefit.title"
        />
        <strong>{{ benefit.title }}</strong>

        <fa-icon
          v-if="index === 0"
          :icon="faChevronDown"
          data-cy="benefits-toggle"
          class="d-block d-md-none position-absolute top-50 end-0 translate-middle-y d-block text-rhino"
          :style="{
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { GetProductQuery } from '~/apollo/types/types';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export default defineComponent({
  props: {
    benefits: {
      type: Array as PropType<GetProductQuery['product_top_bar']>,
      default: () => [],
    },
  },
  setup() {
    const { md } = useAppBreakpoints();

    const isExpanded = ref<boolean>(false);
    const toggleExpand = () => {
      if (md.value) return;
      isExpanded.value = !isExpanded.value;
    };

    return {
      faChevronDown,

      isExpanded,
      md,
      toggleExpand,
    };
  },
});
</script>
<style lang="scss" scoped>
@import 'gportal-theme/scss/_colors.scss';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.landing-page {
  &__top-benefits {
    height: 2.5rem;
    transition: min-height 0.5s ease;
    min-height: 2.5rem;
    overflow: hidden;

    &--expanded {
      min-height: 7.5rem;
    }

    @include media-breakpoint-up(md) {
      height: auto;
      min-height: auto;
    }

    background: linear-gradient(
      to right,
      $gp-midnight-3-a 7%,
      $gp-midnight-3-b 175%
    );
  }
}
</style>
