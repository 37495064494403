<template>
  <span v-if="latency" class="latency-result">
    {{ latency }}ms
    <fa-icon v-if="latency > 150" :icon="faCircle" class="text-danger" />
    <fa-icon v-else-if="latency > 75" :icon="faCircle" class="text-warning" />
    <fa-icon v-else :icon="faCircle" class="text-success" />
  </span>
</template>

<script lang="ts">
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

export default defineComponent({
  name: 'LatencyResult',
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const locationStore = useLocationStore();
    const latency = computed<number | undefined>(() => {
      const result = locationStore.latencyMeasurements.find(
        (l: LatencyMeasurement) => l.slug === props.slug,
      );

      if (result) {
        return result.latency;
      }

      return undefined;
    });

    return {
      latency,
      faCircle,
    };
  },
});
</script>

<style lang="scss" scoped>
.latency-result {
  font-size: 0.8rem;

  svg {
    font-size: 0.6rem;
  }
}
</style>
