<template>
  <div data-cy="review-container" class="container trustpilot-reviews mb-8">
    <div v-if="reviews && reviews.length > 0" class="mt-8">
      <h2 data-cy-review-headline="landingpage/reviews/title" class="mb-0 mb-8">
        {{ $t('landingpage/reviews/title') }}
        <a
          data-cy="review-logo-link"
          href="https://www.trustpilot.com/review/www.g-portal.com"
          rel="noopener"
          target="_blank"
          ><img
            data-cy="review-logo"
            alt="Trustpilot"
            class="figure-img"
            height="32"
            width="130"
            src="/sc/images/trustpilot/logo.svg"
        /></a>
      </h2>

      <div class="row">
        <div
          v-for="review in reviews"
          :key="review.trustpilotId"
          data-cy="review-cards-row"
          class="col-lg-4 col-sm-12 my-lg-0 my-3 align-items-stretch d-flex"
        >
          <div class="card card-review text-white p-2 w-100">
            <div
              data-cy="review-card-header"
              class="card-header d-flex align-items-center"
            >
              <strapi-image
                v-if="review.image"
                :alt="review.author"
                :image="review.image"
                data-cy="review-author-image"
                image-class="rounded-circle"
                :force-height="73"
                :force-width="73"
              />
              <img
                v-else
                :alt="review.author"
                data-cy="review-fallback-image"
                class="rounded-circle"
                height="73"
                width="73"
                src="/sc/images/avatar.jpg"
              />

              <div data-cy="review-author" class="px-5">
                <span>{{ review.author }}</span
                ><br />
                <a
                  data-cy-review-link="review.trustpilotId"
                  :href="`https://www.trustpilot.com/reviews/${review.trustpilotId}`"
                  rel="noopener"
                  target="_blank"
                  ><img
                    alt="5 Sterne"
                    height="20"
                    width="107"
                    src="/sc/images/trustpilot/stars.svg"
                  />
                </a>
              </div>
            </div>
            <div class="card-body">
              <p data-cy-review-text="review.content" class="card-text">
                {{ truncate(review.content, 180) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  GetReviewsDocument,
  type GetReviewsQuery,
  type GetReviewsQueryVariables,
  type TrustPilotReview,
} from '~/apollo/types/types';

const truncate = (text, stop, clamp = null) => {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
};

const { $sentry } = useNuxtApp();
const { locale } = useI18n();
const { data: reviews } = await useAsyncData('gp-strapi-review', async () => {
  try {
    const { data: response } = await useLegacyApollo<
      GetReviewsQuery,
      GetReviewsQueryVariables
    >(GetReviewsDocument, {
      locale: locale.value,
      limit: 3,
    });

    return response.reviews as TrustPilotReview[];
  } catch (error: unknown) {
    $sentry.captureException(error);
  }

  return [];
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors.scss';

.card {
  &-review {
    background: $gp-midnight-1;
    border-radius: 5px;
    box-shadow: 0 0 35px 0 rgb(0 0 0 / 10%);
    border: 1px solid #2f3a44;
  }

  &-review::after {
    content: '\201D';
    position: absolute;
    right: 2rem;
    top: -2rem;
    font-size: 130px;
    font-style: italic;
    color: $gp-rhino;
    opacity: 0.15;
  }
}
</style>
