<template>
  <strapi-content-components>
    <template v-for="c in product.content">
      <strapi-button
        v-if="c.component === 'generic.button'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <strapi-banner
        v-if="c.component === 'generic.banner'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <strapi-product-order-configurations
        v-if="c.component === 'product.order-configurations'"
        :key="c.component"
        :component="c"
        :product-key="product.productKey"
        :product-type="product.type"
        :order-url="product.orderUrl"
        :pre-order="isPreOrder"
      />
      <strapi-product-benefits
        v-if="c.component === 'product.benefits'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <lazy-strapi-product-gallery
        v-if="c.component === 'product.gallery'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <lazy-strapi-media-gallery
        v-if="c.component === 'media-gallery'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <lazy-strapi-product-you-tube
        v-if="c.component === 'product.you-tube-video'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <lazy-strapi-product-content
        v-if="c.component === 'product.content'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <strapi-reviews
        v-if="c.component === 'product.reviews'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <lazy-strapi-product-payments
        v-if="c.component === 'product.payments'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <lazy-strapi-product-faq
        v-if="c.component === 'product.faqs'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <lazy-strapi-product-locations-map
        v-if="c.component === 'product.locations-map'"
        :key="`${c.component}__${c.id}`"
        :component="c"
      />
      <strapi-product-custom-components
        v-if="c.component === 'product.custom-component'"
        :key="`${c.component}__${c.id}`"
        :component="c"
        :order-url="product.orderUrl"
      />
    </template>
  </strapi-content-components>
</template>

<script lang="ts">
import type { StrapiProduct } from '~/apollo/types/types';

export default defineComponent({
  props: {
    product: {
      required: true,
      type: Object as PropType<StrapiProduct>,
    },
  },
  setup(props) {
    const isPreOrder = computed<boolean>(() =>
      timeIsAfterNow(props.product.releaseAt),
    );

    return {
      isPreOrder,
    };
  },
});
</script>
