import type { Product, Review, WithContext } from 'schema-dts';
import {
  type GetProductQuery,
  type StrapiProduct,
  type TrustPilotReview,
  StrapiProductType,
} from '~/apollo/types/types';

export function useGoogleProduct(
  url: string,
  productQuery: GetProductQuery,
): WithContext<Product> | undefined {
  if (
    productQuery === undefined ||
    productQuery.products === undefined ||
    productQuery.products.length !== 1 ||
    productQuery.products[0].type === StrapiProductType.Xbox ||
    productQuery.products[0].startConfiguration == null ||
    productQuery.products[0].startConfiguration.prices.length === 0
  ) {
    return undefined;
  }

  const product = productQuery.products[0] as StrapiProduct;
  const reviews = new Array<Review>();
  // @ts-ignore
  productQuery.reviews.forEach((tr: TrustPilotReview) => {
    reviews.push({
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: tr.author,
      },
      datePublished: tr.publishedAt,
      reviewBody: tr.content,
      name: tr.title,
      url: `https://www.trustpilot.com/reviews/${tr.trustpilotId}`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    });
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    aggregateRating: {
      '@type': 'AggregateRating',
      bestRating: '5',
      ratingCount: productQuery.reviewScore.totalReviews,
      ratingValue: productQuery.reviewScore.trustScore,
      worstRating: '1',
    },
    image: product.coverImage.url,
    name: product.title,
    sku: product.productKey,
    brand: {
      '@type': 'Brand',
      name: 'g-portal.com',
    },
    description: product.metaDescription,
    offers: {
      '@type': 'AggregateOffer',
      url,
      availability: 'https://schema.org/InStock',
      offerCount: 3,
      lowPrice: product.startConfiguration?.prices[0].value
        ? product.startConfiguration.prices[0].value / 100
        : 0,
      priceCurrency: product.startConfiguration?.prices[0].currency,
    },
    review: reviews,
  };
}
